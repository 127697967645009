import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'

const SecondPage = () => (
  <Layout>
    <div className="container">
      <h2>Мы хотим, чтобы ваш бизнес развивался благодаря нашим инструментам для создания долгосрочных отношений со своими клиентами.</h2>
    </div>
    <h1>Возникли вопросы?</h1>
    <p>
      Мы готовы помочь! Напишите нам, и мы свяжемся с вами как можно скорее.
    </p>
  </Layout>
)

export default SecondPage
